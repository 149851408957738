import { lazy } from "react";


import requireAuth from "../components/shared/authentication/require-auth";

import ScrollToTop from "../components/shared/scroll-to-top/ScrollToTop";
import { connect } from "react-redux";

export const forgotPassword = lazy(()=> import("../components/phone-reset/forgot-password/forgot-password"))
export const HemmaSuccessDetails = lazy(()=> import("../components/hemma-success"))
export const BookletDetailsComponent = lazy(()=> import("../components/booklet-for-sell/booklet-details"))
export const {BookletComponent} = lazy(()=> import("../components/booklet-for-sell/booklet-list"))
export const Healthy = lazy(()=> import("../components/shared/healthy"))
export const TrainingResult = lazy(()=> import("../components/categories/quick-questions/training/training-result"))
export const TrainingExamDetails = lazy(()=> import("../components/categories/quick-questions/training/training-details"))
export const StartTrainingExam = lazy(()=> import("../components/categories/quick-questions/training/start-training"))
export const ExamResult = lazy(()=> import('./../components/categories/quick-questions/exams/exam-result'))
export const ExamDetails = lazy(()=> import("../components/categories/quick-questions/exams/exam-details"))
export const StartExam = lazy(()=> import("../components/categories/quick-questions/exams/start-exam"))
export const Preparing = lazy(()=> import("../components/account/certificates/preparing"))
export const Certificate = lazy(()=> import('./../components/account/certificates/certificate'))
export const CertificatesList = lazy(()=> import('./../components/account/certificates/certificates-list'))
export const myFreeGroups = lazy(()=> import('./../components/account/myFreeGroups'))
export const InitiativesDetails = lazy(()=> import('./../components/initiative/initiatives-details'))
export const InitiativesExam = lazy(()=> import('./../components/initiative/initiatives-exam'))
export const InitiativesRole = lazy(()=> import('./../components/initiative/initiatives-role'))
export const EnterToLecture = lazy(()=> import('./../components/initiative/enter-lecture'))
export const BillingList = lazy(()=> import('./../components/account/billings/billing-list'))
export const BillingCourses = lazy(()=> import('./../components/account/billings/billing-courses'))
export const QuestionSummary = lazy(()=> import('./../components/categories/quick-questions/question-summary'))
export const QuickQuestion = lazy(()=> import('./../components/categories/quick-questions/quick-question'))
export const Competition = lazy(()=> import('./../components/categories/competitions/competition'))
export const QuickQuestions = lazy(()=> import('./../components/categories/quick-questions/quick-questions'))
export const Transaction = lazy(()=> import('./../components/cart/transaction'))
export const CertificateTransaction = lazy(()=> import('./../components/account/shared/sidebar/certificate-request/payment-stage/transaction'))
export const FAQ = lazy(()=> import('./../components/faq/faq'))
export const Search = lazy(()=> import('../components/search/search'))
export const BankAccounts = lazy(()=> import('./../components/banks/banks'))
export const Account = lazy(()=> import('./../components/account/settings/account'))
export const NotFound = lazy(()=> import('./../components/shared/not-found/not-found'))
export const Subscriptions = lazy(()=> import('./../components/account/subscriptions/subscriptions'))
export const BenefitBook = lazy(()=> import('./../components/slideShow/benefits-books/benefit-book'))
export const SlideShow = lazy(()=> import('./../components/slideShow/SlideShow'))
export const SitesForTeachers = lazy(()=> import('./../components/sites-for-teachers/sites-for-teachers'))
export const TemplatePreview = lazy(()=> import('./../components/sites-for-teachers/template-preview/template-preview'))
export const SubscriptionDetails = lazy(()=> import('./../components/account/subscriptions/subscription-details'))
export const Cart = lazy(()=> import('./../components/cart/newCart/NewCart'))
export const Checkout = lazy(()=> import('./../components/cart/Checkout'))
export const CertificateCheckout = lazy(()=> import('./../components/account/shared/sidebar/certificate-request/Checkout'))
export const VerifyId = lazy(()=> import('./../components/phone-reset/verify-id/verify'))
export const CourseDetails = lazy(()=> import('./../components/courses/details'))
export const CategoryDetails = lazy(()=> import('./../components/categories/details'))
export const PrivacyPolicy = lazy(()=> import('./../components/privacypolicy/privacypolicy'))
export const NewPrivacyPolicy = lazy(()=> import('./../components/privacypolicy/newprivacypolicy'))
export const Home = lazy(()=> import("./../components/home/home"))
export const Categories = lazy(()=> import('./../components/categories/list'))
export const ScheduleDates = lazy(()=> import('./../components/ScheduleDates'))
export const StudentPercentage = lazy(()=> import('./../components/hemma-services/student-percentage/student-percentage'))
export const MainBlogPage = lazy(()=> import('./../components/blogs/main-blog-page/main-blog-page'))
export const BlogDetails = lazy(()=> import('./../components/blogs/blog-details-page/blog-details'))
export const AllTagBlogs = lazy(()=> import('../components/blogs/all-tag-blogs/all-tag-blogs'))
export const PointsCalculation = lazy(()=> import('./../components/hemma-services/points-calculation/points-calculation'))
export const Verification = lazy(()=> import('./../components/verification/verification'))
export const Auth = lazy(()=> import('./../components/auth/auth'))
export const resetPassword = lazy(()=> import('./../components/phone-reset/reset-password/reset-password'))
export const ShippingInfoForm = lazy(()=> import('./../components/cart/ShippingInfo/ShippingInfoForm'))
export const ShippingInfo = lazy(()=> import('./../components/cart/ShippingInfo/ShippingInfo'))
export const BookletsOrdersList = lazy(()=> import('./../components/account/booklets-orders/booklets-orders-list'))
export const BuyBook = lazy(()=> import('./../components/home/banner-page/buy-book'))
export const CertificatesOrdersList = lazy(()=> import('./../components/account/certificates-orders/certificates-orders-list'))
export const campainCategoryDetails = lazy(()=> import('./../landing-pages/courses-campaign-pages/categories/details'))
export const professionalLicense = lazy(()=> import('./../landing-pages/courses-campaign-pages/pages/professional-license'))
export const abilitiesCourse = lazy(()=> import('./../landing-pages/courses-campaign-pages/pages/abilities-course'))
export const STEP_course = lazy(()=> import('./../landing-pages/courses-campaign-pages/pages/STEP-course'))
export const RegistrationThankPage = lazy(()=> import('../components/shared/thank-pages/Registration-Thank-page'))
export const PayingThankPage = lazy(()=> import('../components/shared/thank-pages/paying-Thank-page'))
