import { GET_SUBSCRIPTION, ADD_RATE_VALUE, SET_PDF_CONTROLES, SET_EXAMPLER_CHAPTERS, SET_EXAMPLER_DYNAMIC_SIDEBAR_ITEMS, GET_SUBSCRIPTION_SIDEBAR, SET_SIDEBAR_LOADER, SET_SHADY_QUESTION_ID, SET_SHADY_EXAM_ID, SET_SHADY_TRAINING_ID, SET_COURSE_CONTENT } from "../actions";
import { sidebarItems } from "../constants/subscription-sidebar-menu/SidebarItems";

const initialState = {
  rateValue: {},
  setPDFControls: false
};
export const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION:
      if (action.error) return state;

      const subscription = action.payload;
      const ratingStatus = action.payload.ratingStatus;
      return {
        subscription,
        ratingStatus,
        setPDFControls: state.setPDFControls
      };
    case ADD_RATE_VALUE:
      return { ...state, rateValue: action.payload };
    case SET_PDF_CONTROLES:
      return { ...state, setPDFControls: action.payload };

    default:
      return state;
  }
};

export const examplerChaptersReducer = (state = {ExamplerChapters: []}, action) => {
  switch (action.type) {
    case SET_EXAMPLER_CHAPTERS:
      return { ...state, ExamplerChapters: action.payload };
    case SET_EXAMPLER_DYNAMIC_SIDEBAR_ITEMS:
      return { ...state, ExemplarDynamicItems: action.payload };
    default:
      return state;
  }
};
export const subscriptionSidebarReducer = (state = {sidebarItems, loader: false}, {type, payload}) => {
  switch (type) {
    case GET_SUBSCRIPTION_SIDEBAR:
      {
        let displayDefualtMenu = false;

        if(payload && payload != [] && payload.length > 0) {
          for(let i = 0; i<payload.length; i++){
            const allPropsExist = payload[i].hasOwnProperty('titleEnglish') && payload[i].hasOwnProperty('titleArabic') && payload[i].hasOwnProperty('order')
            if(!allPropsExist) displayDefualtMenu = true
          }

          if(displayDefualtMenu){
            return {...state, sidebarItems, loader: false}
          }
          else {
            return {...state, sidebarItems: payload, loader: false};
          }
        }
        else return {...state, sidebarItems, loader: false}
      }

    case SET_SIDEBAR_LOADER:
      return {...state, loader: true }

    default:
      return {...state, loader: false};
  }
};

export const ShadyQuestionReducer = (state = {id: ""}, action) => {
  switch (action.type) {
    case SET_SHADY_QUESTION_ID:
      return {id: action.payload };
    default:
      return state;
  }
};

export const ShadyExamReducer = (state = {examId: "", trainingId: ""}, action) => {
  switch (action.type) {
    case SET_SHADY_EXAM_ID:
      return {...state, examId: action.payload };
    case SET_SHADY_TRAINING_ID:
      return {...state, trainingId: action.payload };
    default:
      return state;
  }
};

export const courseContentReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_COURSE_CONTENT:
      return {details: action.payload };
    default:
      return state;
  }
};
