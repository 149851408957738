import React, { Component } from "react";
import "./styles.css";
import { NavLink, Link, withRouter  } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios";
import * as $ from 'jquery';
import 'bootstrap';
import { apiBaseUrl } from "../../../../api/helpers";
import { connect } from "react-redux";
import { signOutAction } from "../../../../actions/login.actions";
import { getUser } from "../../../../actions/user.actions";
import jwtDecode from "jwt-decode";
import {SearchBox, StreachedSearchField} from ".././components/searchBox";
import swal from "@sweetalert/with-react";
import { setHeaderBenefits } from "../../../../actions";
// import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'react-loaders'
import 'loaders.css/src/animations/ball-pulse.scss'
import "loaders.css/src/animations/line-scale.scss";

class HeaderComponent extends Component {
  benefitsBooksPage = 1
  benefitsBooksPageSize = 15

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.arrowRotation = this.arrowRotation.bind(this);
    this.getAllBenefitsBooks = this.getAllBenefitsBooks.bind(this);
    this.state = {
      isOpen: false,
      categories: [],
      categorieslvl1: {},
      categorieslvl2: {},
      categorieslvl3: {},
      categorieslvl4: {},
      isUserMenuOpen: false,
      isMobileUserMenuOpen: false,
      
      benefits: [],
      teacherSites: [
        {
          en_name: "teacher_report",
          ar_name: "شواهد المعلمين والمعلمات", 
        }
      ],
      benefitsBooks: [1,2,3,4,5],
      loadMoreBenefitsBooks: false,
      services: [
        {name: "تواريخ مهمة لك!", route: "/Schedule-dates"}, 
        {name: "احسب نسبتك الموزونة", route: "/student-percentage"},
        // {name: "النماذج اليومية للقدرات", route: "/abilities-models"}, 
      ],

      categoriesGroups:[],
      ClikedTab:"",
      logoutImg: "exit-dark",
      loadingUser: false,
      blogs_IsEnabled: false,
      blogs_pageName: "مدونة همَة",
      TeachersWebsite_IsEnabled: false,
      TeachersWebsite_pageName: "مواقع مهمة للمعلمين",
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  arrowRotation(){
    this.setState({isUserMenuOpen: !this.state.isUserMenuOpen})
  }
  logout = () => {
    localStorage.removeItem('account');
    localStorage.removeItem('checkbox');
    this.props.signOutAction();
    this.props.history.push("/home");
  };
  
  getAllBenefitsBooks = ()=>{
    axios
    .post(`${apiBaseUrl}/HemmaBenefit/GetAllBooks`, {
      page: this.benefitsBooksPage,
      pageSize: this.benefitsBooksPageSize
    })
    .then(response => {
      const allBenefits = [...this.state.benefits, ...response.data?.data?.books]
      this.setState({ benefits: allBenefits});
      this.props.setHeaderBenefits(response.data.data?.books)
      this.benefitsBooksPage++
      this.setState({loadMoreBenefitsBooks: response.data.data?.pager?.totalItems>allBenefits.length})
      setTimeout(
        function () {
          this.setState({loading: false });
        }.bind(this),
        800 
      );
    })
    .catch(error => {
      console.log(error);
    });
  }
  async componentDidMount(){
    var collapseBox = $( "#main-menu-mobile-navbar" );
    var hamburgerClass = $( ".hamburger-classes" );
    const benefitsSubList = $( ".benefits-sub-list" );
    $(".login-btn").on( "click", function() {
      collapseBox.collapse('hide')
      hamburgerClass.removeClass('is-active')
    });
    $(".header-mobile-icon").on( "click", function() {
      collapseBox.collapse('hide')
      hamburgerClass.removeClass('is-active')
    });
    $(".single-benefit").on( "click", function() {
      collapseBox.collapse('hide')
      hamburgerClass.removeClass('is-active')
      // benefitsSubList.removeClass('show')
    });
    $(".blogBtn").on( "click", function() {
      collapseBox.collapse('hide')
      hamburgerClass.removeClass('is-active')
      // benefitsSubList.removeClass('show')
    });
    $(".collapse-close-btn").on( "click", function() {
      collapseBox.collapse('hide')
      hamburgerClass.removeClass('is-active')
    });

    // Start *** Hide Navbar When Clicking On Any User-Profile-menu-Item
    $(".User-menu-Item").on( "click", function() {
      collapseBox.collapse('hide')
      hamburgerClass.removeClass('is-active')
    });
    // End *** Hide Navbar When Clicking On Any User-Profile-menu-Item


    // Start *** Hide Navbar When Clicking OutSide
    $("body").on('click', function (event) {
        var navigation = $(event.target).parents(".navbar").length;
        if(!navigation) {
          collapseBox.collapse("hide");
          hamburgerClass.removeClass('is-active')
        }
    });
    // End *** Hide Navbar When Clicking OutSide
    

    if (this.props.authenticated) {
      try {
        this.setState({loadingUser: true,})
        await this.props.getUser()
        this.setState({loadingUser: false,})
      } catch (error) {
        this.setState({loadingUser: false,})
      }
    }

    if(window.location.href.includes("/home"))
    {
      this.setState({ClikedTab:"Main"});
    }
    else if(window.location.href.includes("/categories"))
    {
      this.setState({ClikedTab:"Category"});
    }
    else if(window.location.href.includes("/booklet"))
    {
      this.setState({ClikedTab:"Booklet"});
    }
    let token = localStorage.getItem("token");
    // Check for token expiration and redirect user to login if token is expired.
    if (token && jwtDecode(token).exp < Date.now() / 1000) {
      localStorage.clear();
      this.props.history.push("/auth/login");
    }
    axios
      .get(`${apiBaseUrl}/categories/CategoryHeader`)
      .then(response => {
       console.log("showthis",response);
       let categories= response.data.data;
        this.setState(
          { 
            categories: categories,
            categorieslvl1 : categories.find(c=>c.level==1),
            categorieslvl2 : categories.find(c=>c.level==2),
            categorieslvl3 : categories.find(c=>c.level==3),
            categorieslvl4 : categories.find(c=>c.level==4),

          }
          );
          PrepareDropdown('category-');
      })
      .catch(error => {
        console.log(error);
      });
      axios
      .get(`${apiBaseUrl}/categories/CategorygroupsHeader`)
      .then(response => {
        this.setState({ categoriesGroups: response.data.data });
        setTimeout(
          function () {
            this.setState({loading: false });
          }.bind(this),
          800 
        );
      })
      .catch(error => {
        console.log(error);
      });
      PrepareDropdown('');
      // axios
      //   .get(`${apiBaseUrl}/HemmaBenefit/GetAllBenefits`)
      //   .then(response => {
      //     this.setState({ benefits: response.data.data });
      //     this.props.setHeaderBenefits(response.data.data)
      //     setTimeout(
      //       function () {
      //         this.setState({loading: false });
      //       }.bind(this),
      //       800 
      //     );
      //     PrepareDropdown('benefits-');
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });

      // ****** Get benefits-books ********
      
      axios
      .post(`${apiBaseUrl}/HemmaBenefit/GetAllBooks`, {
        page: this.benefitsBooksPage,
        pageSize: this.benefitsBooksPageSize
      })
      .then(response => {
        const allBenefits = [...this.state.benefits, ...response.data?.data?.books]
        this.setState({ benefits: allBenefits});
        this.props.setHeaderBenefits(response.data.data?.books)
        this.benefitsBooksPage++
        this.setState({loadMoreBenefitsBooks: response.data.data?.pager?.totalItems>allBenefits.length})
        PrepareDropdown('benefits-');
        setTimeout(
          function () {
            this.setState({loading: false });
          }.bind(this),
          800 
        );
      })
      .catch(error => {
        console.log(error);
      });
      PrepareDropdown('');
      // PrepareDropdown('hemma-services-');

      axios
      .get(`${apiBaseUrl}/Blogs/IsEnable`)
      .then(response => {
        this.setState({ 
          blogs_IsEnabled: response.data?.data?.isEnable,
          blogs_pageName: response.data?.data?.pageName,
        });
      })
      .catch(error => {
        console.log(error);
      });

      axios
      .get(`${apiBaseUrl}/TeachersWebsites/GetTeachersWebsitesName`)
      .then(response => {
        this.setState({ 
          TeachersWebsite_IsEnabled: response.data?.data?.isEnable,
          TeachersWebsite_pageName: response.data?.data?.pageName,
        });
        PrepareDropdown('teacherSites-');
      })
      .catch(error => {
        console.log(error);
      });

      
      function PrepareDropdown(perfix)
      {
        /* Start Collapsing Sub Dropdown Menu By Ckicking */
        // [1] First Sub Menu Of Dropdown Menu
        let linkDropdown = document.querySelectorAll('.'+perfix+'dropdown-sub-wrapper-one');
        let subDropdown = document.querySelectorAll('.'+perfix+'sub-dropdown');
        handleActiveEle(linkDropdown, subDropdown);
        linkDropdown.forEach(linkBtn => {
          linkBtn.onclick = function() {
            if (document.querySelector('.'+perfix+'sub-dropdown2')?.classList.contains('showing') || 
            document.querySelector('.'+perfix+'sub-dropdown3')?.classList.contains('showing')) {
              document.querySelector('.'+perfix+'sub-dropdown2').classList.remove('showing');
              document.querySelector('.'+perfix+'sub-dropdown3').classList.remove('showing');

              document.querySelectorAll('.'+perfix+'dropdown-sub-wrapper-three').forEach(ele => {
                ele.classList.remove('active');
              })
              document.querySelectorAll('.'+perfix+'dropdown-sub-wrapper-four').forEach(ele => {
                ele.classList.remove('active');
              })
              document.querySelectorAll('.'+perfix+'dropdown-sub-wrapper-two').forEach(ele => {
                ele.classList.remove('active');
              })
            } else {
              
            }
          }
        })
       

        /* ______________________________________________________________________ */
      
        // [2] Second Sub Menu Of Dropdown Menu
        let linkDropdownTwo = document.querySelectorAll('.'+perfix+'dropdown-sub-wrapper-two');
        let subDropdownTwo = document.querySelectorAll('.'+perfix+'sub-dropdown2');
        handleActiveEle(linkDropdownTwo, subDropdownTwo);
        linkDropdownTwo.forEach(linkBtn => {
          linkBtn.onclick = function() {
            if (document.querySelector('.'+perfix+'sub-dropdown2').classList.contains('showing')) {
              document.querySelector('.'+perfix+'sub-dropdown3').classList.remove('showing');
              document.querySelectorAll('.'+perfix+'dropdown-sub-wrapper-four').forEach(ele => {
                ele.classList.remove('active');
              })
              document.querySelectorAll('.'+perfix+'dropdown-sub-wrapper-three').forEach(ele => {
                ele.classList.remove('active');
              })
      
            }
          }
        })
        /* ______________________________________________________________________ */
      
        // [3] Third Sub Menu Of Dropdown Menu
        let linkDropdownThree = document.querySelectorAll('.'+perfix+'dropdown-sub-wrapper-three');
        let subDropdownThree = document.querySelectorAll('.'+perfix+'sub-dropdown3');
        handleActiveEle(linkDropdownThree, subDropdownThree);
      
      
      
      
        let mainLinkDropDown = document.querySelector('.'+perfix+'dropdown-wrapper');
        mainLinkDropDown.onmouseover = function(e) {
          let subListMenu = document.querySelector('.' + e.currentTarget.dataset.hover);
          if(!subListMenu.classList.contains('show')) {
            subListMenu.classList.add('show');
          }
        }
         mainLinkDropDown.onmouseout = function(e) {
          let subListMenu = document.querySelector('.' + e.currentTarget.dataset.hover);
          if(subListMenu.classList.contains('show')) {
            subListMenu.classList.remove('show');
          }
        }
        document.addEventListener('mouseover', function(event) {
          var isClickInside = mainLinkDropDown.contains(event.target);
          let subListMenu = document.querySelector('.'+perfix+'sub-list');
          if (isClickInside) { return false; } else {
            subListMenu.classList.remove('show');
            // document.querySelector('.'+perfix+'sub-dropdown').classList.remove('showing');
            // document.querySelector('.'+perfix+'sub-dropdown2').classList.remove('showing');
            // document.querySelector('.'+perfix+'sub-dropdown3').classList.remove('showing');
            document.querySelectorAll('.'+perfix+'sub-dropdown').forEach(ele => {
              ele.classList.remove('showing');
            })
      
            document.querySelectorAll('.'+perfix+'sub-dropdown2').forEach(ele => {
              ele.classList.remove('showing');
            })
      
            document.querySelectorAll('.'+perfix+'sub-dropdown3').forEach(ele => {
              ele.classList.remove('showing');
            })
      
            document.querySelectorAll('.'+perfix+'dropdown-sub-wrapper-one').forEach(ele => {
              ele.classList.remove('active');
            })
      
            document.querySelectorAll('.'+perfix+'dropdown-sub-wrapper-two').forEach(ele => {
              ele.classList.remove('active');
            })
      
            document.querySelectorAll('.'+perfix+'dropdown-sub-wrapper-three').forEach(ele => {
              ele.classList.remove('active');
            })
          }
        });
      }
      
      
        function handleActiveEle(element, list) {


          element.forEach(smEle => {
            smEle.addEventListener('click', (e) => {
if(e.currentTarget.dataset.haschild == "false")
{
return;
}

              e.preventDefault();
              let dropMenu = document.querySelector('.' + e.currentTarget.dataset.dropmenu);
              element.forEach(ele => {
                ele.classList.remove('active');
              });
              list.forEach(subDrop => {
                subDrop.classList.remove('showing');
              });
              e.currentTarget.classList.add('active');
              dropMenu.classList.toggle('showing');
            })
          });
        }

  }

renderSubCategory()
{
  const Categories = this.state.categorieslvl1;
return(
  <React.Fragment>
 <div className="sm-scroll category-drop_one">
  {Categories?.categoriesIds?.map((categoryId,index) => (
    
      Categories.childCategories[categoryId].map((category,index) => (
      <li className="category-dropdown-sub-wrapper-one" data-dropmenu={"drop_"+category.id}  data-hasChild={category.hasChild}>
      <a href={"/categories/details/"+category.id} className="linked">
      <span>{category.nameAr}</span>
        {
          category.hasChild == true? <i className="fas fa-chevron-left font-size-13 lighter-gray drop-icon"></i> : ""
        }
        
       
      </a>
    </li>))
    

  ))}
  </div>
  </React.Fragment>
)
}


renderSubCategorylvl2()
{
  const Categories = this.state.categorieslvl2;
  
return(
  <React.Fragment>

  {Categories?.categoriesIds?.map((categoryId,index) => (
     <div  className={"sm-scroll category-sub-dropdown drop_"+categoryId}>
     { Categories.childCategories[categoryId].map((category,index) => (
      <li className="category-dropdown-sub-wrapper-two" data-dropmenu={"drop_"+category.id} data-hasChild={category.hasChild}>
      <a href={"/categories/details/"+category.id} className="linked" >
      <span>{category.nameAr}</span>
        {
          category.hasChild == true? <i className="fas fa-chevron-left font-size-13 lighter-gray drop-icon"></i> : ""
        }
        
        
      </a>
    </li>)) }
     </div>

  ))}
 
  </React.Fragment>
)
}

renderSubCategorylvl3()
{
  const Categories = this.state.categorieslvl3;
  
return(
  <React.Fragment>

  {Categories?.categoriesIds?.map((categoryId,index) => (
     <div  className={"sm-scroll category-sub-dropdown2 drop_"+categoryId}>
     { Categories.childCategories[categoryId].map((category,index) => (
      <li className="category-dropdown-sub-wrapper-three" data-dropmenu={"drop_"+category.id} data-hasChild={category.hasChild}>
      <a href={"/categories/details/"+category.id} className="linked">
      <span>{category.nameAr}</span>
        {
          category.hasChild == true? <i className="fas fa-chevron-left font-size-13 lighter-gray drop-icon"></i> : ""
        }
        
        
      </a>
    </li>)) }
     </div>

  ))}
 
  </React.Fragment>
)
}
renderSubCategorylvl4()
{
  const Categories = this.state.categorieslvl4;
  
return(
  <React.Fragment>

  {Categories?.categoriesIds?.map((categoryId,index) => (
     <div  className={"sm-scroll category-sub-dropdown3 drop_"+categoryId}>
     { Categories.childCategories[categoryId].map((category,index) => (
      <li className="category-dropdown-sub-wrapper-four" data-dropmenu={"drop_"+category.id} data-hasChild={category.hasChild}>
      <a href={"/categories/details/"+category.id} className="linked" >
      <span>{category.nameAr}</span>
        {
          category.hasChild == true? <i className="fas fa-chevron-left font-size-13 lighter-gray drop-icon"></i> : ""
        }
        
       
      </a>
    </li>)) }
     </div>

  ))}
 
  </React.Fragment>
)
}


  async componentDidUpdate(prevProps, prevState) {
    if (!prevProps.authenticated && this.props.authenticated) {
      // this.props.getUser();
      try {
        this.setState({loadingUser: true,})
        await this.props.getUser()
        this.setState({loadingUser: false,})
      } catch (error) {
        this.setState({loadingUser: false,})
      }
    }
    const collapseBox = $( "#main-menu-mobile-navbar" );
    const hamburgerClass = $( ".hamburger-classes" );
    const benefitsSubList = $( ".benefits-sub-list" );

    $(".single-benefit").on( "click", function() {
      collapseBox.collapse('hide')
      hamburgerClass.removeClass('is-active')
      // benefitsSubList.removeClass('show')
    });
    
    $(".blogBtn").on( "click", function() {
      collapseBox.collapse('hide')
      hamburgerClass.removeClass('is-active')
      // benefitsSubList.removeClass('show')
    });
    if (prevProps.location !== this.props.location) {
      // benefitsSubList.removeClass('show')
    }
  }


  verifyUser = () => {
    this.props.history.push("/verify");
  };
  renderGroupCategory()
  {
    const Categories = this.state.categoriesGroups;
    return(
      <React.Fragment>
         {Categories.map((category,index) => (
<li className="dropdown-sub-wrapper-four">
                    <a href={"/categories/details/"+category.id+"?free=true"} >
                      <span>{category.nameAr}</span>
                    </a>
                  </li>
  ))}
      </React.Fragment>
    )

  }

  renderBenefit() {
    return <React.Fragment>
      {
        this.state.benefits?.map((benefit) => (
          <li className="benefits-dropdown-sub-wrapper-four cursor-pointer">
            <a onClick={() => {
              if (benefit.isPublic === false && !localStorage.getItem("token")) {
                swal(
                  "عفواً",
                  "يجب عليك تسجيل الدخول/تسجيل حساب حتى تتمكن من القيام بهذه الخطوة",
                  "error",
                  {
                    button: "متابعة",
                  }
                ).then(() => this.props.history.push("/auth/login"));
              } else {
                window.location.href = `/SlideShow/${benefit.id}/${benefit.isPublic}`;
              }
            }}>
              <span>{benefit.displayName}</span>
            </a>
          </li>
        ))}
    </React.Fragment>
  }

  renderTeacherSites(){
    return(
      <>
      {
        this.state.teacherSites.length > 0? this.state.teacherSites?.map(site=>
          <li className="teacherSites-dropdown-sub-wrapper-four cursor-pointer ">
            <NavLink 
              to={`/sites-for-teachers/${site.en_name}`}
              activeClassName="active"  
              className="nav-link links-hover collapse-close-btn single-benefit m-0"
            >{site.ar_name}</NavLink>
          </li>
        ): null
      }
      </>
    )
  }

  renderBenefitsBooks(){
    return(
      <>
      {
        this.state.benefits.length > 0? this.state.benefits?.map(book=>
          <li className="benefits-dropdown-sub-wrapper-four cursor-pointer ">
            <NavLink 
              to={`/benefit-books/${book.id}`}
              activeClassName="active"  
              className="nav-link links-hover collapse-close-btn m-0 single-benefit"
            >{book.name}</NavLink>
          </li>
        ): null
      }
      </>
    )
  }

  // renderServices() {
  //   return <React.Fragment>
  //     {
  //       this.state.services.map(({name, route}) => (
  //         <li className="services-dropdown-sub-wrapper-four cursor-pointer">
  //            <a href={route} className="nav-link">
  //             <span>{name}</span>
  //             </a>
  //         </li>
  //       ))}
  //   </React.Fragment>
  // }

  render() {
    console.log("loadMore: ", this.state.loadMoreBenefitsBooks);
    const user = this.props.user;
    const location = this.props?.location;
    const path = this.props?.location?.pathname;
    return (
      <React.Fragment>
    
    {/* <!-- Start The Hemma Navbar --> */}
    <nav id="header-nav" className={`navbar fixed-top navbar-expand-lg navbar-light bg-white py-0 ${path.includes('/courses-campaign-pages')? "d-none" : ""} `}>
      <div className="container flex-lg-column">
        <Link to="/home" className="navbar-brand mr-0 d-lg-none" >
          <img className="logo-img header-mobile-icon"
            src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
            height="60"
            alt="logo"
          />
        </Link>
        {/* <!-- Start The Mobile Button In Small Screen --> */}
        <button className="hamburger hamburger--spring navbar-toggler p-2 hamburger-classes" type="button" data-bs-toggle="collapse" data-bs-target="#main-menu-mobile-navbar" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="d-flex align-items-center justify-content-center">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </span>
        </button>
        {/* <!-- End The Mobile Button In Small Screen --> */}

        {/* <!-- Start The Main Links Of Hemma --> */}
        <div className="collapse navbar-collapse w-100 position-relative" id="main-menu-mobile-navbar">
          <ul className="navbar-nav mb-4 mb-lg-0 mr-auto d-flex align-items-center justify-content-center order-lg-1 logo-part" >
             {/* <!-- Start The Main Hemma Logo --> */}
            <Link to="/home" className="navbar-brand mr-0 d-none d-lg-block" >
              <img className="logo-img header-mobile-icon"
                src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
                height="60"
                alt="logo"
              />
            </Link>
            <div className="d-lg-none">
              <SearchBox isMobileView={true} />
            </div>
            {/* <!-- End The Main Hemma Logo --> */}
            <div className="second-navbar d-flex flex-column flex-lg-row align-items-center justify-content-center" >
              <li className="nav-item d-lg-none">
              {
                this.props.authenticated ? (
                  <div className="user-menu" onClick={()=>this.setState({isUserMenuOpen: !this.state.isUserMenuOpen})}>
                    <UncontrolledDropdown nav inNavbar className={"nav-link d-flex flex-column align-items-center justify-content-between"}>
                      {/* <i className="far fa-user mr-2">  </i> */}
                      <div className="avatar-container mr-2 mb-2 mb-lg-0">
                        <img
                          src={"/assets/images/tomato-avatar.png"}
                          height="20"
                          alt=""
                        /> 
                      </div>
                      {
                        this.state.loadingUser? (
                          <Loader type="line-scale" className="tomato-loader small-lines" />
                        ) : (
                        <DropdownToggle className={"nav-link d-flex flex-column align-items-center justify-content-between"} nav>
                          <div>
                            <span className="sub-color mr-1">
                              {user && user.name}{"  "}
                            </span>
                            <img
                              src={"/assets/images/down-arrow.png"}
                              height="15"
                              alt=""
                              style={{transform: this.state.isUserMenuOpen? "rotateX(180deg)" : "rotateX(0deg)"}}
                            /> 
                            {/* <i className="fas fa-chevron-down font-size-13 ml-1"></i> */}
                          </div>
                        </DropdownToggle>
                        )
                      }
                        <DropdownMenu>
                          <DropdownItem className="p-0 User-menu-Item">
                          <NavLink to="/account/update" className="nav-link d-inline-block w-100">  تعديل الملف</NavLink>
                          </DropdownItem>
                          <DropdownItem className="p-0 User-menu-Item">
                          <NavLink  to="/cart" className="nav-link d-inline-block w-100">مختاراتي</NavLink>
                          </DropdownItem>
                          <DropdownItem className="p-0 User-menu-Item">
                          <NavLink to="/course/content" className="nav-link d-inline-block w-100">دوراتي</NavLink>
                          </DropdownItem>
                          <DropdownItem className="p-0 User-menu-Item">
                          <NavLink to="/booklets-orders" className="nav-link d-inline-block w-100">طلبات الملزمة/البوكس</NavLink>
                          </DropdownItem>
                          <DropdownItem className="p-0 User-menu-Item">
                          <NavLink to="/certificates-orders" className="nav-link d-inline-block w-100">
                            طلبات الشهادات
                          </NavLink>
                          </DropdownItem>
                          <DropdownItem className="p-0 User-menu-Item">
                          <NavLink to="/billing" className="nav-link d-inline-block w-100">الحركات المالية</NavLink>
                          </DropdownItem>
                          <DropdownItem className="p-0 User-menu-Item">
                          <NavLink to="/myFreeGroups" className="nav-link d-inline-block w-100">اختباراتي المجانية</NavLink>
                          </DropdownItem>
                          <DropdownItem className="p-0 User-menu-Item">
                          <NavLink to="/certificates" className="nav-link d-inline-block w-100">شهاداتي</NavLink>
                          </DropdownItem>
                          <DropdownItem className="p-0 User-menu-Item">
                            <a
                              className="nav-link d-inline-flex align-items-end w-100 logo-nav-link"
                              onClick={this.logout}
                              onMouseOver={()=>this.setState({logoutImg: "exit-light"})}
                              onMouseLeave={()=>this.setState({logoutImg: "exit-dark"})}
                            >
                              تسجيل الخروج
                              <img 
                                src={`${process.env.PUBLIC_URL}/assets/images/${this.state.logoutImg}.png`} 
                                width={15}
                                height={15}
                                className="ml-2 mirror"
                                alt=""
                              />
                            </a>
                          </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                ) : null
              }
              </li>
              <li className="nav-item d-none d-lg-inline-block">
                <NavLink to="/home" activeClassName="active"  className="nav-link links-hover collapse-close-btn">الرئيسية</NavLink>
              </li>
              <li className="category-dropdown-wrapper nav-item position-relative" data-hover="category-sub-list">
              <NavLink to="/categories" activeClassName="active" className={"nav-link links-hover d-flex align-items-center justify-content-between"}>
                  <span className="mr-1 header-mobile-icon">منصات همّة</span>
                  <i className="fas fa-chevron-down font-size-13"></i>
                </NavLink>
                <ul className="category-sub-list list-unstyled m-0">
                  {this.renderSubCategory()}
                  {this.renderSubCategorylvl2()}
                  {this.renderSubCategorylvl3()}
                  {this.renderSubCategorylvl4()}
                </ul>
              </li>

              <li className="category-dropdown-wrapper nav-item position-relative" data-hover="category-sub-list">
                <a 
                  href="https://books.hemmastore.org/booklet"
                  target="_blank"
                  className={"nav-link links-hover d-flex align-items-center justify-content-between"}
                >
                كتب القدرات والتحصيلي
                </a>
              </li>
            
              <li className="dropdown-wrapper nav-item position-relative" data-hover="sub-list">
                <a className="nav-link links-hover d-flex align-items-center justify-content-between">
                  <span className="mr-1 header-mobile-icon">اختبارات إلكترونية مجانية</span>
                  <i className="fas fa-chevron-down font-size-13"></i>
                </a>
                <ul className="sub-list list-unstyled m-0">
                <div className="sm-scroll drop_one">
                  { this.renderGroupCategory()}
                
                  </div>
                  </ul>
              </li>
            
              {this.state.benefits?.length > 0 && (
                <li className="benefits-dropdown-wrapper nav-item position-relative" data-hover="benefits-sub-list">
                <a className="nav-link links-hover d-flex align-items-center justify-content-between">
                <span className="mr-1 header-mobile-icon">
                بوربوينت للمعلمين
                </span>
                  <i className="fas fa-chevron-down font-size-13"></i>
                </a>
                <ul className="benefits-sub-list list-unstyled m-0">
                  <div className="sm-scroll drop_one">
                  {/* <InfiniteScroll
                      pageStart={0}
                      loadMore={this.getAllBenefitsBooks}
                      // hasMore={true}
                      hasMore={this.state.loadMoreBenefitsBooks}
                      loader={<Loader type="ball-pulse" className="dark-loader" />}
                      useWindow={false}
                      threshold={10}
                  >
                    {this.renderBenefitsBooks()}
                  </InfiniteScroll> */}
                    {this.renderBenefitsBooks()}
                    {/* {this.renderBenefit()} */}
                  </div>
                </ul>
                </li>
              )}

              {
                (this.state.TeachersWebsite_IsEnabled && this.state.teacherSites?.length > 0)? (
                <li className="teacherSites-dropdown-wrapper nav-item position-relative" data-hover="teacherSites-sub-list">
                <a className="nav-link links-hover d-flex align-items-center justify-content-between">
                <span className="mr-1 header-mobile-icon">
               {this.state.TeachersWebsite_pageName}
                </span>
                  <i className="fas fa-chevron-down font-size-13"></i>
                </a>
                <ul className="teacherSites-sub-list list-unstyled m-0">
                  <div className="sm-scroll drop_one">
                    {this.renderTeacherSites()}
                  </div>
                </ul>
                </li>
                ) : null
              }

            </div>

          </ul>

          <ul className="navbar-nav mb-4 mb-lg-0 mr-auto d-flex align-items-center justify-content-start order-lg-0 " >
            <li className="nav-item position-relative">
              <NavLink to="/Schedule-dates" activeClassName="active"  className="nav-link links-hover collapse-close-btn">
                تواريخ مهمة لك!
              </NavLink>
            </li>
            <li className="nav-item position-relative">
              <NavLink to="/student-percentage" activeClassName="active"  className="nav-link links-hover collapse-close-btn">
              احسب نسبتك الموزونة
              </NavLink>
            </li>
            {
              this.state.blogs_IsEnabled? (
                <li className="nav-item position-relative blog-btn">
                  <NavLink to="/main-blog-page" activeClassName="active"  className="nav-link links-hover collapse-close-btn blogBtn">
                  {this.state.blogs_pageName}
                  </NavLink>
                  <span class="badge badge-pill badge-success smaller">جديد</span>
                </li>
              ):null
            }
            {/* <li className="nav-item position-relative">
              <NavLink to="/points-calculation" activeClassName="active"  className="nav-link links-hover collapse-close-btn">
              حساب النقاط
              </NavLink>
            </li> */}
          </ul>

          <ul className="navbar-nav mb-4 mb-lg-0 d-flex align-items-center justify-content-end order-lg-3">
            <div className="d-none d-lg-block">
              <SearchBox />
            </div>
            {
              !this.props.authenticated ? (
                <>
                  <li className="nav-item d-flex justify-content-center">
                    <NavLink to={{
                      pathname:"/auth/login",
                      state:location?.pathname==='/home'||location?.pathname==='/auth/register'?'home':undefined
                    }} className="btn-sub-color login-btn"> تسجيل الدخول</NavLink>
                  </li>
                  <li className="nav-item">
                  <NavLink to="/auth/register" className="nav-link links-hover login-btn">  إنشاء حساب </NavLink>
                  </li>
                </>
              ):(
                <div className="d-none d-lg-block user-menu" onClick={()=>this.setState({isMobileUserMenuOpen: !this.state.isMobileUserMenuOpen})}>
                  <UncontrolledDropdown nav inNavbar className={"nav-link d-lg-flex align-items-center justify-content-between"}>
                      {/* <i className="far fa-user mr-2">  </i> */}
                      <div className="avatar-container mr-1">
                        <img
                          src={"/assets/images/tomato-avatar.png"}
                          height="20"
                          alt=""
                        /> 
                      </div>
                      {
                        this.state.loadingUser? (
                          <Loader type="line-scale" className="tomato-loader small-lines" />
                        ) : (
                          <DropdownToggle className={"nav-link d-lg-flex align-items-center justify-content-between pl-0 ml-0"} nav>
                            <span className="sub-color mr-1">
                              {user && user.name}
                            </span>
                            <img
                              src={"/assets/images/down-arrow.png"}
                              height="10"
                              alt=""
                              style={{transform: this.state.isMobileUserMenuOpen? "rotateX(180deg)" : "rotateX(0deg)"}}
                            /> 
                            {/* <i className="fas fa-chevron-down font-size-13 ml-1"></i> */}
                          </DropdownToggle>
                        )
                      }
                      <DropdownMenu>
                        <DropdownItem className="p-0 User-menu-Item">
                        <NavLink to="/account/update" className="nav-link d-inline-block w-100">  تعديل الملف</NavLink>
                        </DropdownItem>
                        <DropdownItem className="p-0 User-menu-Item">
                        <NavLink  to="/cart" className="nav-link d-inline-block w-100">مختاراتي</NavLink>
                        </DropdownItem>
                        <DropdownItem className="p-0 User-menu-Item">
                        <NavLink to="/course/content" className="nav-link d-inline-block w-100"> دوراتي</NavLink>
                        </DropdownItem>
                        <DropdownItem className="p-0 User-menu-Item">
                        <NavLink to="/booklets-orders" className="nav-link d-inline-block w-100"> طلبات الملزمة/البوكس</NavLink>
                        </DropdownItem>
                        <DropdownItem className="p-0 User-menu-Item">
                        <NavLink to="/certificates-orders" className="nav-link d-inline-block w-100">طلبات الشهادات</NavLink>
                        </DropdownItem>
                        <DropdownItem className="p-0 User-menu-Item">
                        <NavLink to="/billing" className="nav-link d-inline-block w-100">الحركات المالية</NavLink>
                        </DropdownItem>
                        <DropdownItem className="p-0 User-menu-Item">
                        <NavLink to="/myFreeGroups" className="nav-link d-inline-block w-100">اختباراتي المجانية</NavLink>
                        </DropdownItem>
                        <DropdownItem className="p-0 User-menu-Item">
                        <NavLink to="/certificates" className="nav-link d-inline-block w-100"> شهاداتي</NavLink>
                        </DropdownItem>
                        <DropdownItem className="p-0 User-menu-Item">
                          <a
                            className="nav-link d-inline-flex align-items-end  w-100 logo-nav-link"
                            onClick={this.logout}
                            onMouseOver={()=>this.setState({logoutImg: "exit-light"})}
                            onMouseLeave={()=>this.setState({logoutImg: "exit-dark"})}
                          >
                            تسجيل الخروج
                            <img 
                              src={`${process.env.PUBLIC_URL}/assets/images/${this.state.logoutImg}.png`}
                              width={15}
                              height={15}
                              className="ml-2 mirror"
                              alt=""
                            />
                          </a>
                        </DropdownItem>
                      </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )
            }
          </ul>
         
        </div>
        <div className="container box-shadow d-none d-lg-block" style={{height: "1px", backgroundColor: "#A8EBEF", marginTop: "1px" }}>
          
        </div>
        <div className="w-100 bg-white height-50 d-none d-lg-block" />

        {/* <!-- End The Main Links Of Hemma --> */}

      </div>
      <div className="progressbar-wrapper">
        <div className="progressbar-line"></div>
      </div>
    </nav>
    {/* <!-- End The Hemma Navbar </nav>-->*/}
     
      </React.Fragment>
    );
  }
}


function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    phoneNumberConfirmed: state.auth.phoneNumberConfirmed,
    user: state.user,
  };
}

HeaderComponent = connect(mapStateToProps, { signOutAction, getUser,setHeaderBenefits, })(
  HeaderComponent
);

export const Header = withRouter(HeaderComponent);
